import { __decorate } from "tslib";
import { Vue, Watch, Prop } from "vue-property-decorator";
import Component from "vue-class-component";
import "../plugins/vuetify"; // import Canvas from "./Canvas.vue";

import Frontali from "./Frontali.vue";
import ColoriFrontali from "./ColoriFrontali.vue";
import Aste from "./Aste.vue";
import Clip from "./Clip.vue";
import Nome from "./PersonalName.vue";
import LentiVista from "./LentiVista.vue";
import LentiSole from "./LentiSole.vue";
import User from "./User.vue"; // import Checksum from "./Checksum";

import LanguageCard from "./LanguageCard.vue";
import LanguageButton from "./buttons/LanguageButton.vue";
import ShowCartButton from "./buttons/ShowCartButton.vue";
import FavoriteButton from "./buttons/FavoriteButton.vue";
import HistoryButton from "./buttons/HistoryButton.vue";
import PrintButton from "./buttons/PrintButton.vue";
import ShareButton from "./buttons/ShareButton.vue";
import StoreLocatorButton from "./buttons/StoreLocatorButton.vue";
import StoreLocator from "./StoreLocator.vue";
import _ from "lodash";
let Configurator = class Configurator extends Vue {
  constructor() {
    super(...arguments); // Add prop darkTheme

    this.darkTheme = false;
    this.isMenuOpen = false;
    this.goToFavs = false;
    this.e1 = 1;
    this.collapse = false;
    this.frontali = {
      title: this.$t("message.frontali"),
      active: false,
      settingItem: null
    };
    this.colori = {
      title: this.$t("message.colori"),
      active: false,
      settingItem: null
    };
    this.aste = {
      id: 2,
      title: this.$t("message.Aste"),
      active: false,
      settingItem: null
    };
    this.nome = {
      title: this.$t("message.Nome"),
      active: false,
      image: require("../assets/nomePersonalizzato.png")
    };
    this.miniNav = true;
    this.cartUpdated = false;
    this.cartUpdatedNotify = "";
    this.showCantAddToCart = false;
    this.showCantAddToCart2 = false;
    this.openLanguageSelection = false;
    this.rowNotesDialog = false;
    this.stepperChange = [];
    this.notes = "";
    this.storeLocatorDialog = false;
    this.countryCode = "";
    this.isInDateRange = false;
  }

  async mounted() {
    // if (this.windowsWidth < 768) {
    //   this.activeMenu = "message.frontali";
    // }
    // let canvas = this.$refs.canvas;
    // @ts-ignore
    this.cc = await this.$rest.getCountryCode();

    if (this.userIsGuest) {
      this.countryCode = this.cc.data;
      this.$i18n.locale = this.cc.data;
    } else {
      this.countryCode = this.user.country;
      this.$i18n.locale = this.user.country;
    }

    const startDateEstero = new Date("2024-12-12");
    const startDate = new Date("2024-12-01"); // Inizio intervallo

    const endDate = new Date("2025-01-07"); // Fine intervallo

    const currentDate = new Date();
    this.isInDateRange = currentDate >= startDate && currentDate <= endDate;
    let country = this.cc.data;

    if (country === "IT") {
      this.isInDateRange = currentDate >= startDate && currentDate <= endDate;
    } else {
      this.isInDateRange = currentDate >= startDateEstero && currentDate <= endDate;
    } // If the user is from usa, redirect to usa configurator
    // if (this.usa_country.indexOf(this.countryCode) != -1) {
    //   window.location.href =
    //     "https://usa-igreen-configurator.thema-optical.com";
    // }
    // this.langSelected = this.cc.data;

  }

  closeAlertQrcode() {
    this.activeModelFromQrCode = true;
  }

  get activeModelFromQrCode() {
    return this.$store.state.configurator.activeModelFromQrCode;
  }

  set activeModelFromQrCode(v) {
    this.$store.state.configurator.activeModelFromQrCode = v;
  }

  get frontalModelQrCode() {
    return this.$store.state.configurator.frontalQrCode;
  }

  get frontalColorQrCode() {
    return this.$store.state.configurator.colorQrCode;
  }

  get frontalTemplesColorQrCode() {
    return this.$store.state.configurator.templeQrCode;
  }

  get activeModelText() {
    let phrase = "";

    if (!this.frontalModelQrCode) {
      phrase = this.$t("message.il_modello_non_è_piu_disponibile").toString();
    }

    if (!this.frontalColorQrCode) {
      phrase = this.$t("message.il_colore_non_è_più_disponibile").toString();
    }

    if (!this.frontalTemplesColorQrCode) {
      phrase = this.$t("message.le_aste_non_sono_più_disponibili").toString();
    }

    return phrase;
  }

  canAddToCartMeniscati() {
    // I clienti in "utentiFrontaliniMeniscati" non acquistano il prodotto custom finito ma solo
    // i componenti per assemblarlo, visivamente non ci sono differenze a Configuratore
    // ma nel carrello vengono inseriti dei modelli differenti che corrispondono a frontalini
    // già meniscati
    if (this.$store.state.configurator.user.user && _.indexOf(this.$store.state.utentiFrontaliniMeniscati, this.$store.state.configurator.user.user.username) >= 0 && this.$store.state.configurator.frontale && this.$store.state.configurator.colore && this.$store.state.configurator.calibro != "" && this.$store.state.configurator.naso != "") {
      return true;
    }

    return false;
  }

  emitPopUp() {
    this.$emit("popup-clicked");
  }

  resetSettingItem() {
    this.frontali.settingItem = null;
    this.colori.settingItem = null;
    this.aste.settingItem = null;
  }

  openBottomTab() {
    if (this.collapse == true) {
      this.collapse = false;
    }
  }
  /**
   * If the user has selected sight glasses but no lenses, ask
   * whether he wants to add some lenses
   * Otherwise proceed with the addToCart procedure
   */


  startAddToCart() {
    if (this.cartRows.filter(e => {
      return e.lensleft != "";
    }).length > 0) {
      this.showCantAddToCart = true;
      return;
    }

    if (this.cartRows.filter(e => {
      return e.codice_modello.includes("CLIP");
    }).length > 0 && this.$store.state.configurator.lenteVistaDx != null) {
      this.showCantAddToCart2 = true;
      return;
    }

    if (this.askSightLenses) {
      this.$store.commit("setShowDoYouWantLenses", true);
    } else {
      this.rowNotesDialog = true;
    }
  }
  /**
   * Programmatically move to the sight lenses selection stepper
   */


  goToLenses() {
    // Close dialog and move user to lenses
    this.showDoYouWantLenses = false; // @ts-ignore

    this.$refs.addToCart.$el.scrollIntoView(true, {
      behavior: "smooth"
    });
    this.openBottomTab();
    this.e1 = this.thinClipOnEnabled ? 6 : 5;
  }

  addToCart(openCart) {
    this.rowNotesDialog = false;
    this.$store.state.lenteDx = null;
    this.$store.state.lenteSx = null;
    let frontale = this.$store.state.configurator.frontale; // I due clienti 012719 e 022679 non acquistano il prodotto custom finito ma solo
    // i componenti per assemblarlo, visivamente non ci sono differenze a Configuratore
    // ma nel carrello vengono inseriti dei modelli differenti che corrispondono a frontalini
    // già meniscati

    if (_.indexOf(this.$store.state.utentiFrontaliniMeniscati, this.$store.state.configurator.user.user.username) >= 0) {
      let temp = frontale.U_THE_MODELLO;
      temp = temp.replace(/\D+/g, ""); // rimuovo tutti i caratteri non numerici dal modello

      frontale = this.$store.state.frontaliniMeniscati.filter(e => {
        return temp == e.U_THE_MODELLO.replace(/\D+/g, "");
      });

      if (frontale.length > 0) {
        frontale = frontale[0];
        this.$store.commit("configurator/setFrontalinoMeniscato", frontale);
        this.$store.dispatch("configurator/cart/addFrontaliniMeniscatiToCart");
      } else {
        frontale = null;
      }
    } else {
      this.$store.dispatch("configurator/cart/addToCart");

      if (openCart) {
        this.currentTab = "tab-cart";
        this.profileOpen = true;
      }
    }
  }

  get user() {
    return this.$store.state.configurator.user.user;
  }

  get userIsGuest() {
    return this.$store.getters["configurator/user/isGuest"];
  }

  get cartRows() {
    return this.$store.state.configurator.cart.cartRows;
  }

  get checkoutSuccess() {
    return this.$store.state.configurator.cart.checkoutSuccess;
  }

  get profileOpen() {
    return this.$store.state.profileOpen;
  }

  set profileOpen(newValue) {
    this.$store.commit("setProfileOpen", newValue);
  }

  get englishUserEnabled() {
    return this.$store.getters["englishUserEnabled"];
  }

  get currentTab() {
    return this.$store.state.currentTab;
  }

  set currentTab(newValue) {
    this.$store.commit("setCurrentTab", newValue);
  }

  get configurations() {
    return this.$store.state.configurations;
  }

  get haveConfigurations() {
    var _this$$store$state$co;

    return ((_this$$store$state$co = this.$store.state.configurations) === null || _this$$store$state$co === void 0 ? void 0 : _this$$store$state$co.length) > 0;
  }

  get canAddToCart() {
    return this.$store.getters["configurator/cart/canAddToCart"];
  }

  get isConfigurationSaved() {
    return this.$store.state.configurator.isConfigurationSaved;
  }

  get colore() {
    return this.$store.state.configurator.colore;
  }

  get glassType() {
    return this.$store.state.configurator.glassType;
  }

  get themeColor() {
    return this.darkTheme ? "grey darken-2" : "grey lighten-2";
  }

  get currentSubline() {
    var _this$$store$state$co2;

    return (_this$$store$state$co2 = this.$store.state.configurator.frontale) === null || _this$$store$state$co2 === void 0 ? void 0 : _this$$store$state$co2.U_THE_SOTTOLINEA;
  }

  get thinClipOnEnabled() {
    var _this$$store$state$co3, _this$$store$state$co4;

    return this.currentSubline == 'IG04TH' && ((_this$$store$state$co3 = this.$store.state.configurator.frontale) === null || _this$$store$state$co3 === void 0 ? void 0 : _this$$store$state$co3.U_TIPO_VSP) == 'V' && (_this$$store$state$co4 = this.$store.state.configurator.user) !== null && _this$$store$state$co4 !== void 0 && _this$$store$state$co4.extraAccess ? this.$store.state.configurator.user.extraAccess.includes("IGREEN-THIN-CLIP") : false;
  }

  get cartTotal() {
    return this.cartRows.length;
  }

  get canShowCart() {
    if (!this.userIsGuest && this.cartTotal && this.cartTotal > 0) {
      if (_.indexOf(["EN", "GB", "IE"], this.user.country) >= 0 && this.user.username != "C0000023311") {
        return false;
      } else {
        return true;
      }
    }

    return false;
  }

  get note() {
    return this.$store.state.configurator.note;
  }

  set note(newVal) {
    this.$store.commit("configurator/setRowNotes", newVal);
  }

  get frontModel() {
    var _this$$store$state$co5, _this$$store$state$co6;

    return (_this$$store$state$co5 = this.$store.state.configurator.frontale) === null || _this$$store$state$co5 === void 0 ? void 0 : (_this$$store$state$co6 = _this$$store$state$co5.U_THE_MODELLO) === null || _this$$store$state$co6 === void 0 ? void 0 : _this$$store$state$co6.replace(/([0-9]+)(S)/i, "$1");
  }

  get frontSize() {
    return this.$store.getters["configurator/frontSize"];
  }

  get frontColor() {
    return this.$store.getters["configurator/frontColor"];
  }

  get templesColor() {
    return this.$store.getters["configurator/templesColor"];
  }

  get showDoYouWantLenses() {
    return this.$store.state.showDoYouWantLenses;
  }

  set showDoYouWantLenses(newVal) {
    this.$store.commit("setShowDoYouWantLenses", newVal);
  }

  get askSightLenses() {
    return this.glassType == "V" && (_.isEmpty(this.$store.state.configurator.lenteVistaSx) || _.isEmpty(this.$store.state.configurator.lenteVistaDx));
  }

  get ConfigurationProgress() {
    let progress = 0;

    if (this.frontModel) {
      progress += 25;
    }

    if (this.frontSize) {
      progress += 25;
    }

    if (this.frontColor) {
      progress += 25;
    }

    if (this.templesColor) {
      progress += 25;
    }

    return progress;
  }

  get validateFrontali() {
    return this.frontModel && !_.isEmpty(this.frontSize);
  }

  get validateColori() {
    return !_.isEmpty(this.frontColor);
  }

  get validateAste() {
    return !_.isEmpty(this.templesColor);
  }

  get validateClip() {
    return this.$store.state.configurator.numberOfClips == this.$store.state.configurator.lentiSoleClip.length;
  }

  get validateNome() {
    return !_.isEmpty(this.$store.state.configurator.frontali.name);
  }

  get validateLenti() {
    if (this.glassType == "V") {
      return !_.isEmpty(this.$store.state.configurator.lenteVistaSx) && !_.isEmpty(this.$store.state.configurator.lenteVistaDx);
    } else {
      return !_.isEmpty(this.$store.state.configurator.lenteSole);
    }
  }

  get validateLentiVista() {
    return this.glassType == "V" && !_.isEmpty(this.$store.state.configurator.lenteVistaSx) && !_.isEmpty(this.$store.state.configurator.lenteVistaDx);
  }

  get getFavIcon() {
    if (this.isConfigurationSaved != -1) {
      return "favorite";
    } else {
      return "favorite_border";
    }
  }

  changedUser(nVal) {
    if (nVal) {
      this.countryCode = this.$store.state.configurator.user.user.country; // this.langSelected = nVal.country;

      this.$i18n.locale = this.$store.state.configurator.user.user.country;
    } else if (nVal == null) {
      this.countryCode = this.cc.data;
      this.$i18n.locale = this.cc.data; // this.langSelected = this.cc.data;
    }
  }

  onMiniNavChange() {
    this.frontali.active = false;
    this.colori.active = false;
    this.aste.active = false;
  }

  onCartTotalChange(newVal, oldVal) {
    if (oldVal < newVal) {
      /*delta = newVal - oldVal
              this.cartUpdatedNotify = delta +' '+ this.$t('message.elemento_aggiunto_al_carrello')+'!'*/
      this.cartUpdated = true;
    }
  }

  onE1Change(newVal, oldVal) {
    this.stepperChange = [oldVal, newVal];
  }

};

__decorate([Prop()], Configurator.prototype, "darkTheme", void 0);

__decorate([Watch("user")], Configurator.prototype, "changedUser", null);

__decorate([Watch("miniNav")], Configurator.prototype, "onMiniNavChange", null);

__decorate([Watch("cartTotal")], Configurator.prototype, "onCartTotalChange", null);

__decorate([Watch("e1")], Configurator.prototype, "onE1Change", null);

Configurator = __decorate([Component({
  components: {
    Frontali,
    ColoriFrontali,
    Aste,
    Clip,
    Nome,
    LentiVista,
    LentiSole,
    User,
    LanguageCard,
    LanguageButton,
    ShowCartButton,
    FavoriteButton,
    HistoryButton,
    PrintButton,
    ShareButton,
    StoreLocatorButton,
    StoreLocator
  }
})], Configurator);
export default Configurator;